
 #sticky-footer {
    width: 40%;
    margin-left: 30%;
    margin-top: 11%;
}
.fontSize{
    font-size: 10px
}
.footerCopyright{
    color: #0000008a
}