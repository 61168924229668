.ui.container.flex {
  height: 75vh;
}
button.backbtnstyle.btn.btn-primary {
  background-color: #65A5F6;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #65A5F6;
  border-color: #007bff;
}
@media only screen and (max-width: 500px) {
  .smallNavbar{
    width: fit-content;
  }
}
