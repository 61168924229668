p.pTag1 {
    color: #02151575;
}
p.pTag2 {
    color: #02151575;
    margin-top: 3%;

    margin-bottom: 3%;
}
.allpTags {
    margin-top: 1%;
}
p.pTag3 {
    color: #02151575;
}
img.appStore {
    width: 12%;
    margin-top: 3%;
    cursor: pointer;
}
p.paraTagAppDownload {
    text-align: center;
    margin-top: 0%;
    color: #03010a6e;
}
.loginlogosubmitAppDownload{
    margin-top: 1%;
    width: 350px;
}