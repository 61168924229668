.navbarcolor{
    background-color: #62a3f9;
}
a.nav-item.secondnav.nav-link{
    color: aliceblue
}
a.navclass.nav-link {
    color: white;
}
nav.navbarcolor.navbar.navbar-expand.navbar-light.sticky-top {
    height: 27px;
}
