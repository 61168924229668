.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    cursor: default;
}
.fit-content{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

 .emailclass{
     text-align: center;
     font-size: medium;
     margin-left: 4%;
     color: silver;
     margin-top: 5%
 }
 .outerLabel {
    text-align: left;
}
 .outerFormInput {
    width: 90%;
}
 .passwordclass{
    text-align: center;
    font-size: medium;
    margin-top: 5%;
}
.card {
    background-color: #1f2b29;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}


.form-signin {
    margin-top: 4%;
    width: 90%;
}
.outerForm {
    text-align: -webkit-center;
    text-align: -moz-center;
}
h2.invitationline {
    font-size: smaller;
    color: #bcbdbd;
    margin-top: 10%;
    text-align: center
}
.btn.btn-signin {
    margin: 2%;
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    background-color: #62a3f9;
    color: aliceblue;
}
.hcenter{
    text-align: center;
    color: papayawhip;
}
.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: rgb(22, 123, 238);
}

.forgot-password {
    color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: rgb(12, 97, 33);
}

img.bckroundimg {
    width: 350px;
    margin-top: 8%

}
.outerLogoImage {
    text-align: center;
}
.paraTag{
    text-align: center;
    margin-top: 1%;
    color: #03010a6e;
}
.outerLogoImg {
    text-align: center;
}
.loginlogosubmit {
    margin-top: 8%;
    width: 350px;
}
.loginlogoUser {
    width: 100px;
}

form.form-signin.submitone {
    width: 100%;
    margin-left: 0%;
}
#inputPassword{
    margin-bottom: 3%
}
.forgot-loginSubmit {
    font-size: small;
    color: #02151575;
}
button.btn.btn-primary.mrginToModl {
    margin-left: 42%;
}
p.emailNotFound {
    font-size: smaller;
    color: #0805029e;
}
/* .modal-content {
    border-radius: 8%;
} */
.forgotDiv{
    text-align: center;
 }
 .password-field {
    margin-left: 15%;
    margin-right: 15%;
}
@media only screen and (max-width: 500px) {
    form.form-signin.submitone {
        width: 100%;
        margin-left: 0;
     }
     .forgotDiv{
        text-align: center;
     }
     .forgot-loginSubmit {
        margin-left: 0;
        font-size: small;
        color: #02151575;
    }
    .loginlogosubmit {
    width: 100%;
    margin-left: 0%;
    height: 100%;
}
  }
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    .forgotDiv{
        text-align: center;
     }
     .forgot-loginSubmit {
        margin-left: 0;
        font-size: small;
        color: #02151575;
    }
  }
.navbarcolor{
    background-color: #62a3f9;
}
a.nav-item.secondnav.nav-link{
    color: aliceblue
}
a.navclass.nav-link {
    color: white;
}
nav.navbarcolor.navbar.navbar-expand.navbar-light.sticky-top {
    height: 27px;
}


 #sticky-footer {
    width: 40%;
    margin-left: 30%;
    margin-top: 11%;
}
.fontSize{
    font-size: 10px
}
.footerCopyright{
    color: #0000008a
}
.ui.container.flex {
  height: 75vh;
}
button.backbtnstyle.btn.btn-primary {
  background-color: #65A5F6;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #65A5F6;
  border-color: #007bff;
}
@media only screen and (max-width: 500px) {
  .smallNavbar{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.bessemerDashboard{
    width: 92%;
    height: 100%;
}
.dashboard.container {
    margin-top: 3%;
}
.clientsEstates{
    font-size: small;
    color: #02090e9e
}
.welcomeTag{
    color: #02090e9e

}
.portalView{
    font-size: smaller;
    color: #06060570;
}
.inform{
    line-height: 0%;
    margin-bottom: 1%
}
br {
    display: block;
    margin: 10px 0;
 }
 .container.bootstraptable {
    margin-top: 4%;
}
.csvbutton{
    background-color: black;
    color: white;
    font-size: x-small;
    height: 3rem;
    display: flex
}
.infoestate{
    font-size: x-small;
    margin-bottom: 0%
}
tr {
    font-size: x-small;
    color: #0c0a0aa3;
}
.eyeClass{
    color: #62a3f9;
    cursor: pointer;
    font-size: large
}
.completeicon{
    font-size: large
}
.noEstate{
    margin-top: 0;
    font-size: small;
    color: #10100eb0;
}
.lightNoEstate {
    font-size: smaller;
    color: #0000008a;
}
.noEstateContainer{
    width: 56%;
    margin-left: 22%;
}
p.modalEstate {
    font-size: smaller;
    color: #010d17b3;
}
.row.react-bootstrap-table-pagination {
    display: block;
    text-align: -webkit-center;
    text-align: -moz-center;
}
ul.pagination.react-bootstrap-table-page-btns-ul {
    justify-content: center;
    margin-top: 2%;
}
.react-bootstrap-table-pagination-total {
    font-size: small;
    color: #040403bd;
}
.logoForLead{
    height: 100%;
    width: 100%;
}
p.smallSizebutton {
    font-size: xx-small;
    margin-bottom: 0%
}
.logoForPro{
    height: 100%;
    width: 100%;    
}
.csvbuttonlead{
    background-color: black;
    color: white;
    font-size: x-small;
    height: 2.4rem;
    display: grid
}
.outerLogoDiv{
    text-align: center;
    height: 100%;
    width: 100%;
}
.btnNdCsv {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
}
@media only screen and (max-width: 575px) {
    .inform{
        text-align: center
    }
    .csvbutton {
        background-color: black;
        color: white;
        font-size: x-small;
        height: 4rem;
        display: grid
    }
   
    .logoForPro{
        height:50%;
        width: 50%;    
        margin-bottom: 5%;
    }
    .logoForLead{
        height: 50%;
        width: 50%;
        margin-bottom: 5%;

    }
    .outerLogoDiv{
        text-align: center;
        height: 100%;
        width: 100%;
    }
    .outerLogoViewPage{
        text-align: center;
    }
   
  }
  @media only screen and (max-width: 765px) {
    .csvbuttonlead{
        background-color: black;
        color: white;
        font-size: x-small;
        height: 4rem;
        display: grid
    }
}
@media (max-width:1200px) and (min-width:765px) {
    .csvbuttonlead{
        background-color: black;
        color: white;
        font-size: x-small;
        height: 3.4rem;
        display: grid
    }
}
p.welcometag {
    color: #00000082;
}
p.estatename {
    font-size: smaller;
    color: #00000082;
}
p.sameTags{
    font-size: smaller;
    color: #00000082;
    margin-bottom: 2%
}
.csvbuttonview{
    background-color: black;
    color: white;
    font-size: smaller;
    height: 32px;
    margin: 2%;
}
.addnewitemtag{
    margin-top: 3%;
}
.csvbuttons {
    text-align: right;
    display: flex;
    flex-flow: row-reverse;
}
.csvbuttoninsidetable{
    background-color: black;
    color: white;
    font-size: xx-small;
    height: 32px;
    margin: 2%;
    margin-top: 40%;
}
img.imagesinsidetable {
    margin: 1%;
}
.loginlogoViewPage {
    width: 100%;
    height: 100%;
}
.backMargin{
    text-align: right
}
.particularImages {
    display: contents;
}
img.paddding {
    padding: 1vh;
    cursor: pointer;
}
.sweet-loading{
    text-align: center
}
.back-icon{
    margin-left: 2px;
}
.outerLogoViewPage{
    width: 100%;
    height: 100%;
}
.imgNdCsv {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
}
@media only screen and (max-width: 1024px) {

.csvbuttonview {
    height: 40px;
}

}
@media only screen and (max-width: 575px) {
    .loginlogoViewPage {
        width: 50%;
        height: 50%;
        margin-bottom: 5%;
    }
    
}
@media only screen and (max-width: 767px) {
    .csvbuttoninsidetable{
        background-color: black;
        color: white;
        font-size: xx-small;
        height: 46px;
        margin: 2%;
    }
}

p.pTag1 {
    color: #02151575;
}
p.pTag2 {
    color: #02151575;
    margin-top: 3%;

    margin-bottom: 3%;
}
.allpTags {
    margin-top: 1%;
}
p.pTag3 {
    color: #02151575;
}
img.appStore {
    width: 12%;
    margin-top: 3%;
    cursor: pointer;
}
p.paraTagAppDownload {
    text-align: center;
    margin-top: 0%;
    color: #03010a6e;
}
.loginlogosubmitAppDownload{
    margin-top: 1%;
    width: 350px;
}
.form-gap {
    padding-top: 70px;
}
.justified{
    justify-content: center
}
.headerModal {
  align-self: center;
}
.noInternetText {
  text-align: center;
}

