.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}
.fit-content{
    width: fit-content
}

 .emailclass{
     text-align: center;
     font-size: medium;
     margin-left: 4%;
     color: silver;
     margin-top: 5%
 }
 .outerLabel {
    text-align: left;
}
 .outerFormInput {
    width: 90%;
}
 .passwordclass{
    text-align: center;
    font-size: medium;
    margin-top: 5%;
}
.card {
    background-color: #1f2b29;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}


.form-signin {
    margin-top: 4%;
    width: 90%;
}
.outerForm {
    text-align: -webkit-center;
    text-align: -moz-center;
}
h2.invitationline {
    font-size: smaller;
    color: #bcbdbd;
    margin-top: 10%;
    text-align: center
}
.btn.btn-signin {
    margin: 2%;
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    background-color: #62a3f9;
    color: aliceblue;
}
.hcenter{
    text-align: center;
    color: papayawhip;
}
.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: rgb(22, 123, 238);
}

.forgot-password {
    color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: rgb(12, 97, 33);
}

img.bckroundimg {
    width: 350px;
    margin-top: 8%

}
.outerLogoImage {
    text-align: center;
}
.paraTag{
    text-align: center;
    margin-top: 1%;
    color: #03010a6e;
}
.outerLogoImg {
    text-align: center;
}
.loginlogosubmit {
    margin-top: 8%;
    width: 350px;
}
.loginlogoUser {
    width: 100px;
}

form.form-signin.submitone {
    width: 100%;
    margin-left: 0%;
}
#inputPassword{
    margin-bottom: 3%
}
.forgot-loginSubmit {
    font-size: small;
    color: #02151575;
}
button.btn.btn-primary.mrginToModl {
    margin-left: 42%;
}
p.emailNotFound {
    font-size: smaller;
    color: #0805029e;
}
/* .modal-content {
    border-radius: 8%;
} */
.forgotDiv{
    text-align: center;
 }
 .password-field {
    margin-left: 15%;
    margin-right: 15%;
}
@media only screen and (max-width: 500px) {
    form.form-signin.submitone {
        width: 100%;
        margin-left: 0;
     }
     .forgotDiv{
        text-align: center;
     }
     .forgot-loginSubmit {
        margin-left: 0;
        font-size: small;
        color: #02151575;
    }
    .loginlogosubmit {
    width: 100%;
    margin-left: 0%;
    height: 100%;
}
  }
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    .forgotDiv{
        text-align: center;
     }
     .forgot-loginSubmit {
        margin-left: 0;
        font-size: small;
        color: #02151575;
    }
  }