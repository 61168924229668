p.welcometag {
    color: #00000082;
}
p.estatename {
    font-size: smaller;
    color: #00000082;
}
p.sameTags{
    font-size: smaller;
    color: #00000082;
    margin-bottom: 2%
}
.csvbuttonview{
    background-color: black;
    color: white;
    font-size: smaller;
    height: 32px;
    margin: 2%;
}
.addnewitemtag{
    margin-top: 3%;
}
.csvbuttons {
    text-align: right;
    display: flex;
    flex-flow: row-reverse;
}
.csvbuttoninsidetable{
    background-color: black;
    color: white;
    font-size: xx-small;
    height: 32px;
    margin: 2%;
    margin-top: 40%;
}
img.imagesinsidetable {
    margin: 1%;
}
.loginlogoViewPage {
    width: 100%;
    height: 100%;
}
.backMargin{
    text-align: right
}
.particularImages {
    display: contents;
}
img.paddding {
    padding: 1vh;
    cursor: pointer;
}
.sweet-loading{
    text-align: center
}
.back-icon{
    margin-left: 2px;
}
.outerLogoViewPage{
    width: 100%;
    height: 100%;
}
.imgNdCsv {
    max-width: max-content;
}
@media only screen and (max-width: 1024px) {

.csvbuttonview {
    height: 40px;
}

}
@media only screen and (max-width: 575px) {
    .loginlogoViewPage {
        width: 50%;
        height: 50%;
        margin-bottom: 5%;
    }
    
}
@media only screen and (max-width: 767px) {
    .csvbuttoninsidetable{
        background-color: black;
        color: white;
        font-size: xx-small;
        height: 46px;
        margin: 2%;
    }
}
