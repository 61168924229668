.bessemerDashboard{
    width: 92%;
    height: 100%;
}
.dashboard.container {
    margin-top: 3%;
}
.clientsEstates{
    font-size: small;
    color: #02090e9e
}
.welcomeTag{
    color: #02090e9e

}
.portalView{
    font-size: smaller;
    color: #06060570;
}
.inform{
    line-height: 0%;
    margin-bottom: 1%
}
br {
    display: block;
    margin: 10px 0;
 }
 .container.bootstraptable {
    margin-top: 4%;
}
.csvbutton{
    background-color: black;
    color: white;
    font-size: x-small;
    height: 3rem;
    display: flex
}
.infoestate{
    font-size: x-small;
    margin-bottom: 0%
}
tr {
    font-size: x-small;
    color: #0c0a0aa3;
}
.eyeClass{
    color: #62a3f9;
    cursor: pointer;
    font-size: large
}
.completeicon{
    font-size: large
}
.noEstate{
    margin-top: 0;
    font-size: small;
    color: #10100eb0;
}
.lightNoEstate {
    font-size: smaller;
    color: #0000008a;
}
.noEstateContainer{
    width: 56%;
    margin-left: 22%;
}
p.modalEstate {
    font-size: smaller;
    color: #010d17b3;
}
.row.react-bootstrap-table-pagination {
    display: block;
    text-align: -webkit-center;
    text-align: -moz-center;
}
ul.pagination.react-bootstrap-table-page-btns-ul {
    justify-content: center;
    margin-top: 2%;
}
.react-bootstrap-table-pagination-total {
    font-size: small;
    color: #040403bd;
}
.logoForLead{
    height: 100%;
    width: 100%;
}
p.smallSizebutton {
    font-size: xx-small;
    margin-bottom: 0%
}
.logoForPro{
    height: 100%;
    width: 100%;    
}
.csvbuttonlead{
    background-color: black;
    color: white;
    font-size: x-small;
    height: 2.4rem;
    display: grid
}
.outerLogoDiv{
    text-align: center;
    height: 100%;
    width: 100%;
}
.btnNdCsv {
    max-width: max-content;
}
@media only screen and (max-width: 575px) {
    .inform{
        text-align: center
    }
    .csvbutton {
        background-color: black;
        color: white;
        font-size: x-small;
        height: 4rem;
        display: grid
    }
   
    .logoForPro{
        height:50%;
        width: 50%;    
        margin-bottom: 5%;
    }
    .logoForLead{
        height: 50%;
        width: 50%;
        margin-bottom: 5%;

    }
    .outerLogoDiv{
        text-align: center;
        height: 100%;
        width: 100%;
    }
    .outerLogoViewPage{
        text-align: center;
    }
   
  }
  @media only screen and (max-width: 765px) {
    .csvbuttonlead{
        background-color: black;
        color: white;
        font-size: x-small;
        height: 4rem;
        display: grid
    }
}
@media (max-width:1200px) and (min-width:765px) {
    .csvbuttonlead{
        background-color: black;
        color: white;
        font-size: x-small;
        height: 3.4rem;
        display: grid
    }
}